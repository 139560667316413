const crawlerList = [
  {
    pattern: 'GoogleOther',
  },
  {
    pattern: 'Googlebot\\/',
  },
  {
    pattern: 'Googlebot-Mobile',
  },
  {
    pattern: 'Google-InspectionTool',
  },
  {
    pattern: 'bingbot',
  },
  {
    pattern: 'yandex\\.com\\/bots',
  },
  {
    pattern: 'Baiduspider',
  },
  {
    pattern: 'Twitterbot',
  },
  {
    pattern: 'facebookexternalhit',
  },
  {
    pattern: 'rogerbot',
  },
  {
    pattern: 'LinkedInBot',
  },
  {
    pattern: 'Embedly',
  },
  {
    pattern: 'quote link preview',
  },
  {
    pattern: 'showyoubot',
  },
  {
    pattern: 'siteliner',
  },
  {
    pattern: 'klarnabot',
  },
  {
    pattern: 'outbrain',
  },
  {
    pattern: 'pinterest.com.bot',
  },
  {
    pattern: 'Slackbot',
  },
  {
    pattern: 'vkShare',
  },
  {
    pattern: 'W3C_Validator',
  },
  {
    pattern: 'Google-Structured-Data-Testing-Tool',
  },
  {
    pattern: 'Ahrefs(Bot|SiteAudit)',
  },
];

export default crawlerList;

import React, { Fragment } from 'react';
import { NavItem, Container, Navbar, Nav } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import NavigationEntity from '../../../types/NavigationEntity';
import NavigationEntityItem from '../../navigation/NavigationEntityItem';
import ConfigStore from '../../../store/ConfigStore';
import { modelOf } from '../../../prop-types';
import CustomNavigationLinkLocationType from '../../../types/CustomNavigationLinkLocation';
import NavigationCustomEntityItem from '../NavigationCustomEntityItem';
import NavigationScrollIndicator from '../NavigationScrollIndicator';

const NavigationSectionSlider = ({ configStore, items }) => {
  const getNavItems = () => {
    return items.map((item) => {
      const { id, className, customNavLinks } = item;
      return (
        <Fragment key={uniqueId(id)}>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, index) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.ABOVE &&
                customNavLink.parent_sibling_id === item.id && (
                  <NavigationCustomEntityItem
                    key={uniqueId(id)}
                    customNavLink={customNavLink}
                    index={index}
                  />
                )
            )}
          <NavItem
            className={classNames('NavigationSectionSlider__item', className)}
          >
            {getNavigationElement(item)}
          </NavItem>
          {customNavLinks &&
            customNavLinks.map(
              (customNavLink, index) =>
                customNavLink.location ===
                  CustomNavigationLinkLocationType.BELOW &&
                customNavLink.parent_sibling_id === item.id && (
                  <NavigationCustomEntityItem
                    key={uniqueId(id)}
                    customNavLink={customNavLink}
                    index={index}
                  />
                )
            )}
        </Fragment>
      );
    });
  };

  const getNavigationElement = (item) => {
    const { path } = item;
    const linkClassName = 'NavigationSectionSlider__link';

    if (path) {
      return (
        <NavigationEntityItem
          key={item.id}
          entity={item}
          className={linkClassName}
        />
      );
    }
  };

  const navItems = getNavItems();

  return (
    <Navbar className={classNames('NavigationSectionSlider')} expand="lg">
      <Container
        className={classNames({
          NavigationSectionSlider__container: configStore.topNavigation.center,
        })}
      >
        <Nav navbar className="NavigationSectionSlider__nav">
          {navItems}
          <NavigationScrollIndicator />
        </Nav>
      </Container>
    </Navbar>
  );
};

NavigationSectionSlider.propTypes = {
  items: PropTypes.arrayOf(NavigationEntity).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(NavigationSectionSlider));

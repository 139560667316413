import { types } from 'mobx-state-tree';

import BaseSkeleton from './BaseSkeleton';
import ProductListSkeleton from './productList/ProductListSkeleton';

const PlaceholderSkeleton = types.optional(
  types.model('PlaceholderSkeleton', {
    productList: types.union(ProductListSkeleton, BaseSkeleton),
  }),
  {
    productList: {
      height: window.innerHeight * 1.3,
    },
  }
);

export default PlaceholderSkeleton;

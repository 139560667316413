import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import Page from '../../components/layout/Page';
import WysiwygContent from '../../components/common/WysiwygContent';
import ProductTabs from '../../components/product/ProductTabs';
import ConfigStore from '../../store/ConfigStore';
import { modelOf } from '../../prop-types';
import GenericLayout from '../../components/layout/GenericLayout';
import MainBanner from '../../components/ad/MainBanner';
import ScrollReset from '../../components/common/ScrollReset';
import AdPages from '../../types/AdPages';
import RouteService from '../../services/RouteService';
import SEOHomePage from '../../components/head/SEOHomePage';
import CommonPage from '../../types/CommonPage';
import AdStore from '../../store/AdStore';
import AdZones from '../../types/AdZones';
import ContentPopups from '../../components/ad/ContentPopups';

@observer
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.yotpoTimeout = null;

    this.maybeLoadAds();
  }

  componentDidMount() {
    const { configStore } = this.props;
    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => window.yotpo.refreshWidgets(), 800);
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  maybeLoadAds = () => {
    const { adStore } = this.props;

    if (this.shouldHaveAds()) {
      adStore.loadAds(this.getSearchParams()).catch((e) => console.error(e));
    }
  };

  getSearchParams = () => {
    return {
      bannerPage: AdPages.FRONT_PAGE,
    };
  };

  shouldHaveAds = () => {
    const { adStore } = this.props;
    return adStore.frontPageAds.zones.length > 0;
  };

  shouldHaveMainAds = () => {
    const { adStore } = this.props;
    return adStore.frontPageAds.zones.indexOf(AdZones.MAIN_BANNER) !== -1;
  };

  shouldHaveSidebarAds = () => {
    const { adStore } = this.props;
    return adStore.frontPageAds.zones.indexOf(AdZones.SIDEBAR_BANNER) !== -1;
  };

  shouldHavePopup = () => {
    const { adStore } = this.props;
    return adStore.frontPageAds.zones.indexOf(AdZones.POPUP_CONTENT) !== -1;
  };

  render() {
    const { adStore, configStore } = this.props;

    const mainBanner = this.shouldHaveMainAds() ? (
      <MainBanner
        searchParams={this.getSearchParams()}
        shouldHaveMobile={adStore.frontPageAds.hasMobile}
      />
    ) : null;
    const mainBannerWithinContent =
      configStore.banner.mainBanner.withinContent &&
      !configStore.banner.mainBanner.fullWidth;
    return (
      <Page name={CommonPage.HOME} className="HomePage">
        <ScrollReset />
        <SEOHomePage />
        {!mainBannerWithinContent && mainBanner}
        <Container className="HomePage__container">
          <GenericLayout
            bannerSearchParams={
              this.shouldHaveSidebarAds() ? this.getSearchParams() : null
            }
            content={
              <>
                {mainBannerWithinContent && mainBanner}
                <div className="SectionPage__section-content">
                  <WysiwygContent html={configStore.frontpageText} />
                </div>
                <ProductTabs disableQuickSearch requireCanonicalLink={true} />
                {this.shouldHavePopup() && (
                  <ContentPopups searchParams={this.getSearchParams()} />
                )}
              </>
            }
          />
        </Container>
      </Page>
    );
  }
}

HomePage.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject('adStore', 'configStore', 'routeService')(HomePage);

import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { uniqueId } from 'lodash';

import { modelOf } from '../../../prop-types';
import LanguageStore from '../../../store/LanguageStore';
import CountryStore from '../../../store/CountryStore';
import SectionStore from '../../../store/SectionStore';

const LOADBEE_TEMPLATE = '_catalogue';
const LOADBEE_DEBUG = 'false';

@observer
export class LoadBeeSectionContentWidget extends Component {
  getLocale = () => {
    const { countryStore, languageStore } = this.props;

    return (
      languageStore.activeLocale.toLowerCase() +
      '_' +
      countryStore.activeCountry.iso_code_2.toUpperCase()
    );
  };

  render() {
    const section = this.props.sectionStore.activeSection;
    const catalogue = section.slug;

    if (!section.integration_api_key || !section.integration_feed_url) {
      return null;
    }

    return (
      <Fragment>
        <Helmet>
          <script
            data-id={uniqueId('LoadBeeSectionContentWidget-')}
            async
            src="https://cdn.loadbee.com/js/loadbee_integration.js"
          />
        </Helmet>
        <div
          className="LoadBeeSectionContentWidget loadbeeTabContent"
          id="loadbeeTabContentId"
          data-loadbee-apikey={section.integration_api_key}
          data-loadbee-catalogue={catalogue}
          data-loadbee-template={`${catalogue}${LOADBEE_TEMPLATE}`}
          data-loadbee-locale={this.getLocale()}
          data-loadbee-cat-products={section.integration_feed_url}
          data-loadbee-debug={LOADBEE_DEBUG}
        />
      </Fragment>
    );
  }
}

LoadBeeSectionContentWidget.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
};

export default inject(
  'sectionStore',
  'countryStore',
  'languageStore'
)(LoadBeeSectionContentWidget);

import React from 'react';
import { Container } from 'reactstrap';

import Icon from '../../common/Icon';

const NavigationScrollIndicator = ({}) => {
  return (
    <>
      <div className="NavigationScrollIndicator__spacer"></div>
      <div className="NavigationScrollIndicator">
        <Container className="NavigationScrollIndicator__container">
          <Icon
            name="angle-right"
            className="NavigationScrollIndicator__icon"
          />
        </Container>
      </div>
    </>
  );
};

export default NavigationScrollIndicator;
